<template>
  <div>
    <div v-if="!configuredIssns.includes($route.params.issn)"> 
      <h2> ISSN Not Configured for Taxomomy Functions </h2>
    </div>
    <div v-if="finishedLoadingTaxonomy"> 
      <b-tabs>
        <b-tab title="Type">
          <b-card>
            <b-row> 
              <b-col cols="3">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="addTag('type')"
                >
                  <feather-icon icon="PlusIcon" />
                  Add Tag
                </b-button>
              </b-col>
              <b-col cols="7"/>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="getTaxonomyExportLink('Article Types')"
                >
                  <feather-icon icon="DownloadCloudIcon" />
                  Export
                </b-button>
              </b-col>
            </b-row>
            <br><br>
            <div  v-for="(item, index) in activityTypeData"  :key="index">
              <b-row>
                <b-col cols="2" style="margin-top:.8rem;">
                  <nobr>{{ item.tagUri }}</nobr>
                </b-col>
                <b-col cols="10">
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="labelForTagEntry(tagUriLabels[item.tagUri])"
                    variant="flat-primary"
                  >
                    <b-dropdown-item
                      @click.stop="promptForEntryModal(item)"
                    >
                      <feather-icon icon="EditIcon"/>
                      Edit Tag
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.stop="promptForTagRemoval(item)"
                    >
                      <feather-icon icon="Trash2Icon"/>
                      Remove Tag
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Topic"> 
          <b-card> 
            <b-row> 
              <b-col cols="3">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="addTag('topic')"
                >
                  <feather-icon icon="PlusIcon" />
                  Add Tag
                </b-button>
              </b-col>
              <b-col cols="7"/>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="getTaxonomyExportLink('Topics')"
                >
                  <feather-icon icon="DownloadCloudIcon" />
                  Export
                </b-button>
              </b-col>
            </b-row>
            <br>
            <div  v-for="(item, index) in topicData"  :key="index">
              <b-row>
                <b-col v-if="item.parentTag" cols="1"/>
                <b-col v-if="item.parentTag" cols="2" style="margin-top:.8rem;">
                  <nobr>{{ item.tagUri }}</nobr>
                </b-col>
                <b-col cols="9">
                  <span v-if="item.parentTag">
                    <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :text="labelForTagEntry(tagUriLabels[item.tagUri])"
                      variant="flat-primary"
                    >
                      <b-dropdown-item
                        @click.stop="promptForEntryModal(item)"
                      >
                        <feather-icon icon="EditIcon"/>
                        Edit Tag
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click.stop="promptForTagRemoval(item)"
                      >
                        <feather-icon icon="Trash2Icon"/>
                        Remove Tag
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                  <span v-else>
                    <br>
                    <b>{{ labelForTagEntry(tagUriLabels[item.tagUri]) }}</b>
                  </span>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Badge">
          <b-card>
            <b-row> 
              <b-col cols="3">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="addTag('badge')"
                >
                  <feather-icon icon="PlusIcon" />
                  Add Tag
                </b-button>
              </b-col>
              <b-col cols="7"/>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="getTaxonomyExportLink('Badges')"
                >
                  <feather-icon icon="DownloadCloudIcon" />
                  Export
                </b-button>
              </b-col>
            </b-row>
            <br><br>
            <div  v-for="(item, index) in badgeData"  :key="index">
              <b-row>
                <b-col cols="2" style="margin-top:.8rem;">
                  <nobr>{{ item.tagUri }}</nobr>
                </b-col>
                <b-col cols="10">
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="labelForTagEntry(tagUriLabels[item.tagUri])"
                    variant="flat-primary"
                  >
                    <b-dropdown-item
                      @click.stop="promptForEntryModal(item)"
                    >
                      <feather-icon icon="EditIcon"/>
                      Edit Tag
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.stop="promptForTagRemoval(item)"
                    >
                      <feather-icon icon="Trash2Icon"/>
                      Remove Tag
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Special Feature">
          <b-card>
            <b-row> 
              <b-col cols="3">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="addTag('sp-feat')"
                >
                  <feather-icon icon="PlusIcon" />
                  Add Tag
                </b-button>
              </b-col>
              <b-col cols="7"/>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="getTaxonomyExportLink('Special Feature')"
                >
                  <feather-icon icon="DownloadCloudIcon" />
                  Export
                </b-button>
              </b-col>
            </b-row>
            <br><br>
            <div  v-for="(item, index) in specialFeatureData"  :key="index">
              <b-row>
                <b-col cols="2" style="margin-top:.8rem;">
                  <nobr>{{ item.tagUri }}</nobr>
                </b-col>
                <b-col cols="10">
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="labelForTagEntry(tagUriLabels[item.tagUri])"
                    variant="flat-primary"
                  >
                    <b-dropdown-item
                      @click.stop="promptForEntryModal(item)"
                    >
                      <feather-icon icon="EditIcon"/>
                      Edit Tag
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.stop="promptForTagRemoval(item)"
                    >
                      <feather-icon icon="Trash2Icon"/>
                      Remove Tag
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Colloquia">
          <b-card>
            <b-row> 
              <b-col cols="3">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="addTag('colloquia')"
                >
                  <feather-icon icon="PlusIcon" />
                  Add Tag
                </b-button>
              </b-col>
              <b-col cols="7"/>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="margin-top:-.5rem;"
                  variant="outline-primary"
                  class="btn-icon"
                  @click.stop="getTaxonomyExportLink('Colloquia')"
                >
                  <feather-icon icon="DownloadCloudIcon" />
                  Export
                </b-button>
              </b-col>
            </b-row>
            <br><br>
            <div  v-for="(item, index) in colloquiaData"  :key="index">
              <b-row>
                <b-col cols="2" style="margin-top:.8rem;">
                  <nobr>{{ item.tagUri }}</nobr>
                </b-col>
                <b-col cols="10">
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="labelForTagEntry(tagUriLabels[item.tagUri])"
                    variant="flat-primary"
                  >
                    <b-dropdown-item
                      @click.stop="promptForEntryModal(item)"
                    >
                      <feather-icon icon="EditIcon"/>
                      Edit Tag
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.stop="promptForTagRemoval(item)"
                    >
                      <feather-icon icon="Trash2Icon"/>
                      Remove Tag
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="History"> 
          <br>
          <b-card>
            <app-timeline>
              <app-timeline-item 
                v-for="(item, index) in history"  :key="index"
                :icon="iconForHistoryItem(item)"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>{{ textForHistoryItem(item) }}</h6>
                  <small class="text-muted">{{ item.timestamp | formatISODate }}</small>
                </div>
                <p>{{ item.summary }} </p>
                <br>
                <div v-if="item.user">{{ item.user.first_name }} {{ item.user.last_name }}</div>
              </app-timeline-item>
            </app-timeline>
          </b-card>
        </b-tab>
      </b-tabs>
      <!--modals here -->
      <!-- modal Taxonomy entry -->
      <b-modal 
        id="taxonomy-entry-modal-id" 
        title="Taxonomy Entry" 
      >
        <span v-if="entryModel && !entryModel.new.remove">
          <b-row>
            <b-col cols="3">
              <b>Tax Uri</b>
            </b-col>
            <b-col cols="6">
              <b-form-select
                v-model="entryModel.new.taxUri"
                :options="taxUriOptions"
                size="sm"
              />
            </b-col>
          </b-row>
          <br>
          <span v-if="entryModel.new.taxUri == 'topic'">
            <b-row>
              <b-col cols="3">
                <b>Parent</b>
              </b-col>
              <b-col cols="6">
                <b-form-select
                  v-model="entryModel.new.parentTag"
                  :options="topicParentUriOptions"
                  size="sm"
                />
              </b-col>
            </b-row>
            <br>
          </span>
          <b-row>
            <b-col cols="3">
              <b>Tag Uri</b>
            </b-col>
            <b-col cols="6">
              <b-form-input
                v-model="entryModel.new.tagUri"
                size="sm"
              />
            </b-col>
          </b-row>
          <br>
          <b-row> <b-col><b>Label</b></b-col></b-row>
          <b-row><b-col>
            <b-form-input
              v-model="entryModel.new.tagUriLabel"
              size="sm"
            />
          </b-col></b-row>
          <span v-if="errorOnSave">
            <br>
            <b class="text-danger">{{ errorOnSave }}</b>
          </span>
        </span>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-left"
              @click.stop="closeTaxonomyEntryModal()"
            >
              Cancel
            </b-button>
            <b-button 
              v-if="validEntry"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary" 
              size="sm"
              class="float-right"
              @click.stop="saveTaxonomyEntry()" 
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              /> 
              Save 
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal
        id="prompt-for-tag-removal-id" 
        centered
        title="Tag Removal" 
        ok-title="Remove"
        cancel-title="Cancel"
        size="lg"
        @ok="saveTaxonomyEntry"
      >
        <span v-if="entryModel && entryModel.new.remove">
          <p><b class="text-danger"> Tag to be removed</b></p>
          <p>{{ tagUriLabels[entryModel.old.tagUri] }} </p>
          <br>
          <p>Click Remove to proceed.</p>
          <br>
        </span>
      </b-modal>
    </div>
  </div>
</template>
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import fileMgrApi from '@/dl_pubmill/apis/fileMgr'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { $themeConfig } from '@themeConfig'

import {
  BRow, BCol, BCard, BTabs, BTab, BButton, BFormSelect, BFormInput, BDropdown, BDropdownItem
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {

  components: {
    BRow,
    BCol,
    BCard,
    BTabs, 
    BTab,
    BButton,
    BFormSelect,
    BFormInput,
    BDropdown,
    BDropdownItem,
    AppTimeline,
    AppTimelineItem,
  }, 
  directives: {
    Ripple,
  },

  data () {
    return {
      tagUriLabels: null,
      finishedLoadingTaxonomy: null,
      activityTypeData: null,
      topicData: null,
      badgeData: null,
      specialFeatureData: null,
      colloquiaData: null,
      history: null,
      entryModel: null,
      taxUriOptions: [
        { value: 'type', text: 'Type' },
        { value: 'topic', text: 'Topic' },
        { value: 'badge', text: 'Badge' },
        { value: 'sp-feat', text: 'Special Feature' },
        { value: 'colloquia', text: 'Colloquia' },
      ],
      topicParentUriOptions: [
        { value: 'bio-sci', text: 'Biological Sciences' },
        { value: 'phys-sci', text: 'Physical Sciences' },
        { value: 'soc-sci', text: 'Social Sciences' },
      ],
      errorOnSave: null,
      configuredIssns: ['1091-6490', 'PNAS-SNBX']
    }
  },
  computed: {
    validEntry () { 
      if (this.entryModel == null) {
        return false
      }
      if (this.entryModel.new.taxUri  === 'topic') {
        if (this.entryModel.new.parentTag == null) {
          return false
        }
        if (this.entryModel.new.parentTag !== this.entryModel.old.parentTag) {
          return true
        }
      }
      if (this.entryModel.new.tagUri.trim() === '') {
        return false
      }
      if (this.entryModel.new.tagUriLabel.trim() === '') {
        return false
      }
      return (this.entryModel.new.taxUri !== this.entryModel.old.taxUri 
        || this.entryModel.new.tagUri !== this.entryModel.old.tagUri
        || this.entryModel.new.tagUriLabel !== this.entryModel.old.tagUriLabel)
    },
  },
  mounted () { 
    if ($themeConfig.app.envVars.FORMAT === 'literatum') {
      this.getTaxonomy() 
    } else {
      this.$router.push({ name: 'search', params: { issn: this.$route.params.issn } })
    }
  },
  methods: {
    iconForHistoryItem (item) {
      if (item.type === 'add') {
        return 'PlusSquareIcon'
      }
      if (item.type === 'update') {
        return 'EditIcon'
      } 
      if (item.type === 'remove') {
        return 'DeleteIcon'
      } 
      return 'CircleIcon'
    },
    textForHistoryItem (item) {
      if (item.type === 'add') {
        return 'Tag Added'
      }
      if (item.type === 'update') {
        return 'Tag Updated'
      } 
      if (item.type === 'remove') {
        return 'Tag Removed'
      } 
      return 'Unknown'
    },
    labelForTagEntry (label) { 
      let returnLabel = label
      if (returnLabel.length > 100) {
        returnLabel = returnLabel.substring(0, 99).concat('...')
      }
      return returnLabel
    },
    addTag (taxUri) { 
      this.errorOnSave = null
      this.entryModel = {
        old: {},
        new: {
          taxUri, 
          tagUri: '', 
          tagUriLabel: '', 
        },
      }
      this.$bvModal.show('taxonomy-entry-modal-id')
    },
    promptForEntryModal (item) { 
      //console.log('iiiiiiiiii', item)
      this.errorOnSave = null
      this.entryModel = {
        old: {
          taxUri: item.taxUri, 
          parentTag: item.parentTag, 
          tagUri: item.tagUri, 
          tagUriLabel: this.tagUriLabels[item.tagUri], 
        },
        new: {
          taxUri: item.taxUri, 
          parentTag: item.parentTag, 
          tagUri: item.tagUri, 
          tagUriLabel: this.tagUriLabels[item.tagUri], 
        },
      }  
      this.$bvModal.show('taxonomy-entry-modal-id')
    },
    closeTaxonomyEntryModal () {
      this.entryModel = null
      this.errorOnSave = null
      this.$bvModal.hide('taxonomy-entry-modal-id')
    },
    promptForTagRemoval (item) { 
      //console.log('iiiiiiiiii', item)
      this.entryModel = {
        old: {
          taxUri: item.taxUri, 
          parentTag: item.parentTag, 
          tagUri: item.tagUri, 
          tagUriLabel: this.tagUriLabels[item.tagUri], 
        },
        new: {
          remove: 'y'
        },
      }  
      this.$bvModal.show('prompt-for-tag-removal-id')
    },
    buildTaxUriDataOn (taxIn) {
      const parentChildRelationships = {}
      for (let i = 0; i < taxIn.tagUris.length; i++) {
        const tagUri =  taxIn.tagUris[i]
        if (tagUri.parentTag) {
          if (!parentChildRelationships[tagUri.parentTag]) {
            parentChildRelationships[tagUri.parentTag] = []
          }
          parentChildRelationships[tagUri.parentTag].push(tagUri)
        }
      }
      //console.log('parentChildRelationships', parentChildRelationships)
      const taxOut = []
      const keys = Object.keys(parentChildRelationships)
      //console.log('keys', keys)
      keys.sort()
      for (let i = 0; i < keys.length; i++) {
        let toRec = { taxUri: taxIn.taxUri, tagUri: keys[i] }
        taxOut.push(toRec)
        //console.log('parent', toRec.tagUri)
        const tagUris = parentChildRelationships[keys[i]]
        for (let j = 0; j < tagUris.length; j++) {
          toRec = { taxUri: taxIn.taxUri, tagUri: tagUris[j].tagUri, parentTag: tagUris[j].parentTag }
          //console.log('child', toRec.tagUri,  toRec.parentTag)
          taxOut.push(toRec)
        } 
      }
      for (let i = 0; i < taxIn.tagUris.length; i++) {
        const tagUri = taxIn.tagUris[i]
        //console.log('dddddddddddd', tagUri)
        //skip root tags because they were added above
        if (!tagUri.parentTag && parentChildRelationships[tagUri.tagUri]) {
          //console.log('skipping ', tagUri)
          continue
        }
        //skip tags with parent because they were added above
        if (tagUri.parentTag) {
          //console.log('skipping ', tagUri)
          continue
        }
        const toRec = { taxUri: taxIn.taxUri, tagUri: tagUri.tagUri, parentTag: tagUri.parentTag }
        taxOut.push(toRec)
      }
      //console.log('ttttxxx out', taxOut)
      return taxOut 
    },
    getTaxonomy () {
      if (!this.configuredIssns.includes(this.$route.params.issn)) {
        return
      }
      this.activityTypeData = null
      this.topicData = null
      this.badgeData = null
      this.specialFeatureData = null
      this.colloquiaData = null
      this.finishedLoadingTaxonomy = null
      publicationApi.getTaxonomy(this.$route.params.issn, 'y', this, null)
    },
    backFromGetTaxonomy (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        return
      } 
      console.log('backFromTaxonomy', serverData)
      if (!serverData.taxUris) {
        this.finishedLoadingTaxonomy = 'y'
        return
      }
      this.tagUriLabels = serverData.tagUriLabels

      this.activityTypeData = this.buildTaxUriDataOn(serverData.taxUris['Article Types'])
      this.topicData = this.buildTaxUriDataOn(serverData.taxUris.Topics)
      this.badgeData = this.buildTaxUriDataOn(serverData.taxUris.Badges)
      this.specialFeatureData = this.buildTaxUriDataOn(serverData.taxUris['Special Feature'])
      this.colloquiaData = this.buildTaxUriDataOn(serverData.taxUris.Colloquia)
      this.history = serverData.taxonomy_audit

      const tempDict = {} 
      let data = this.activityTypeData
      //console.log('aaaaa', data)
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.topicData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        //console.log('00000', data[i].tagUri)
        tempDict[key] = data[i]
        //console.log('111111', key, tempDict[key])
      }
      data = this.badgeData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.specialFeatureData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.colloquiaData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      this.tagUriDict = tempDict
  
      this.finishedLoadingTaxonomy = 'y'

    }, 
    saveTaxonomyEntry () {
      this.errorOnSave = null 
      console.log('ssssssssss', this.entryModel)
      if (!this.entryModel.new.remove) {
        this.entryModel.new.tagUri = this.entryModel.new.tagUri.trim()
        this.entryModel.new.tagUriLabel = this.entryModel.new.tagUriLabel.trim()
        
        if (this.entryModel.new.tagUri !== this.entryModel.old.tagUri
          && this.tagUriLabels[this.entryModel.new.tagUri]) {
          this.errorOnSave = 'Tag Uri already in use.  Please select a unique value.'
          return
        }
      }
      publicationApi.saveTaxonomyEntry(this.$route.params.issn, this.entryModel, this, null)
  
    },
    backFromSaveTaxonomyEntry(serverData) {
      console.log('backFromSaveTaxonomyEntry', serverData)
      if (serverData.error) {
        console.log('serverData.error', serverData.error)
        this.$bvModal
          .msgBoxOk(serverData.error, {
            title: 'Sorry.  There was a problem with this operation',
            size: 'sm',
            okVariant: 'danger',
            modalClass: 'modal-danger',
            okTitle: 'Ok',
          })
      } else { 
        this.closeTaxonomyEntryModal()
        this.getTaxonomy()
      }
    },
    getTaxonomyExportLink (taxUriName) {
      const queryParams = {
        issn: this.$route.params.issn,
        tax_uri_name: taxUriName,
      } 
      fileMgrApi.getTaxonomyExportLink(queryParams, this, null)
    },

    backFromGetTaxonomyExportLink (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('zzzzzzzzzzzzzxxx', serverData)
        /* do this when server data is the fike blob and not a url
        const fileURL = window.URL.createObjectURL(new Blob([serverData]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'zzz.xml')
        document.body.appendChild(fileLink)
        fileLink.click() 
        */
        //since serverData  is a url  ( one that has a presigned s3 url )
        const fileURL = serverData
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        document.body.appendChild(fileLink)
        fileLink.click() 
      } 
    },
  }
}
</script>
